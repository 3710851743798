<script>
import moment from 'moment';
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import Multiselect from 'vue-multiselect'
import 'vue2-datepicker/locale/pt-br';
import ProductService from '@/services/product'
import { VMoney } from 'v-money'

moment.locale('pt-BR');

export default {
  page: {
    title: "Regras de Limite de Produtos (Mobwit)",
  },
  components: { Layout, PageHeader, Multiselect },
  directives: { money: VMoney },
  data() {
    return {
      title: "Regras de Limite de Produtos (Mobwit)",
      loading: false,
      departmentLimitRules: [],
      departmentLimitRule: {},
      departments: [],
      modalError: '',
      departmentLimitRuleToRemove: '',

      searchValue: '',
      count: 0,
      currentCount: 0,
      currentPage: 1,
      totalPages: 0,
      perPage: 0,
      startIndex: 0,
      endIndex: 0,

      money: {
        decimal: ',',
        thousands: '.',
        prefix: '',
        suffix: '',
        precision: 2,
        masked: false,
      },
      fields: [
        { key: 'departmentDescription', sortable: false, label: 'Grupo/Departamento' },
        { key: 'quantity', sortable: false, label: 'Qtde. Máxima' },
        { key: 'registerDate', sortable: false, label: 'Data de Cadastro', formatter: (value) => {
          return moment(value).format('DD/MM/YYYY hh:mm:ss');
        } },
        { key: 'actions', label: 'Ações', class: 'nowrap-col' }
      ],
    };
  },
  mounted() {
    this.load();
  },
  methods: {
    async load(showLoading = true, page = 1, term = '') {
      let loader;

      if (showLoading) {
        loader = this.$loading.show();
      }

      try {
        const departmentsResult = await ProductService.getDepartments();
        this.departments = departmentsResult.data;

        const departmentLimitRulesResult = await ProductService.getDepartmentLimitRuleMobwit(term, page);
        this.departmentLimitRules = departmentLimitRulesResult.data.departmentLimitRules;

        this.count = departmentLimitRulesResult.data.count;
        this.currentCount = departmentLimitRulesResult.data.currentCount;
        this.currentPage = departmentLimitRulesResult.data.currentPage;
        this.totalPages = departmentLimitRulesResult.data.totalPages;
        this.perPage = departmentLimitRulesResult.data.perPage;
        this.startIndex = departmentLimitRulesResult.data.startIndex;
        this.endIndex = departmentLimitRulesResult.data.endIndex;

        if (showLoading) {
          loader.hide();
        }
      } catch (err) {
        if (showLoading) {
          loader.hide();
        }
        this.$toast.error('Ocorreu um erro ao carregar as configurações');
      }
    },
    async saveDepartmentLimitRule() {
      this.modalError = '';

      if (!this.departmentLimitRule._id && !this.departmentLimitRule.department) {
        return this.modalError = 'Informe o departamento da regra';
      }

      if (!this.departmentLimitRule.quantity) {
        return this.modalError = 'Informe a quantidade máxima corretamente';
      }

      const data = {
        ...this.departmentLimitRule,
        quantity: Number(this.departmentLimitRule.quantity.toString().replace('.', '').replace(',', '.')),
        origin: 'mobwit',
      }

      this.$bvModal.hide('modal-edit');

      let loader = this.$loading.show();

      try {
        if (this.departmentLimitRule._id) {
          await ProductService.updateDepartmentLimitRule(data);
        } else {
          await ProductService.createDepartmentLimitRule(data);
        }

        loader.hide();

        this.load(false);

        this.$toast.open('Dados atualizados com sucesso');
      } catch (err) {
        loader.hide();
        
        if (err && err.response.data.code && err.response.data.code === 'E9999') {
          return this.$toast.warning(err.response.data.msg);
        }

        this.$toast.error('Ocorreu um erro inesperado');
      }
    },
    onPageChange() {
      window.scrollTo(0,0);
      this.load(true, this.currentPage);
    },
    search() {
      this.searchEmptyField = false,
      this.load(true, 1, this.searchValue);
    },
    changeSearch() {
      if (this.searchValue === '' && !this.searchEmptyField) {
        this.searchEmptyField = true;
        this.load(true, 1, this.searchValue);
      }
    },
    newDepartmentLimitRule() {
      this.departmentLimitRule = {
      }

      this.modalError = '';
      this.$bvModal.show('modal-edit');
    },
    editDepartmentLimitRule(item) {
      this.departmentLimitRule = {
        _id: item._id,
        department: item.department,
        departmentDescription: item.departmentDescription,
        quantity: item.quantity.toString().replace('.', ','),
      }

      this.modalError = '';
      this.$bvModal.show('modal-edit');
    },
    removeDepartmentLimitRule() {
      this.$bvModal.hide('modal-remove');

      ProductService.removeDepartmentLimitRule(this.departmentLimitRuleToRemove).then(() => {
        this.load(false);
        this.$toast.open('Regra removida com sucesso');
      }).catch(() => {
        this.$toast.error('Ocorreu um erro ao remover a regra');
      })
    }
  },
};
</script>

<template>
  <Layout>
    <PageHeader :title="title"/>
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <div class="row mb-2 align-items-center">
              <div class="col-sm-8 col-lg-6">
                <div class="mr-2 mb-2">
                  <div class="position-relative search-input">
                    <form @submit.prevent="search" class="align-items-center">
                      <input
                        type="text"
                        class="form-control"
                        v-model="searchValue"
                        v-on:keyup="changeSearch"
                        placeholder="Pesquisar por nome do grupo"
                      />

                      <button type="submit" class="btn btn-primary" v-if="!loading">
                        Pesquisar
                      </button>

                      <a href="javascript:void(0);" class="text-primary" v-if="loading">
                        <b-spinner class="ml-2" variant="primary" role="status"></b-spinner>
                      </a>
                    </form>
                  </div>
                </div>
              </div>

              <div class="col-sm-4 col-lg-6">
                <div class="text-sm-right">
                  <button type="button" class="btn btn-primary btn-rounded mb-2 mr-2" @click="newDepartmentLimitRule()">
                    <i class="mdi mdi-plus mr-1"></i> Nova Regra  
                  </button>
                </div>
              </div>
            </div>  

            <div>
             
              <b-table
                show-empty
                emptyText="Nenhum registro para exibir"
                :items="departmentLimitRules"
                :fields="fields"
                responsive="sm"
                head-variant="light"
              >
                <template v-slot:cell(actions)="row">
                 

                  <a href="javascript:void(0);" class="action-icon text-success mr-3" @click="editDepartmentLimitRule(row.item)">
                    <i class="mdi mdi-pencil font-size-18"></i>
                  </a>
                  <a href="javascript:void(0);" class="action-icon text-danger" v-b-modal.modal-remove @click="departmentLimitRuleToRemove = row.item._id" >
                    <i class="mdi mdi-close-circle-outline font-size-18"></i>
                  </a>
                </template>
              </b-table>

              <div class="row justify-content-md-between align-items-md-center mt-4">
                <div class="col-xl-7">Mostrando {{startIndex}} - {{endIndex}} de {{count}}</div>
                <!-- end col-->
                <div class="col-xl-5">
                  <div class="text-md-right float-xl-right mt-2 pagination-rounded">
                    <b-pagination
                      v-model="currentPage" 
                      :total-rows="count"
                      :per-page="perPage"
                      @input="onPageChange"
                    ></b-pagination>
                  </div>
                </div>
                <!-- end col-->
              </div>
            </div>
          </div>
          <!-- end card-body -->
        </div>
        <!-- end card -->
      </div>
      <!-- end col -->
    </div>
    <!-- end row -->

    <b-modal id="modal-edit" :title="departmentLimitRule._id ? 'Alterando Regra' : 'Nova Regra'" centered>
      <b-alert v-if="modalError" v-model="modalError" variant="danger" dismissible>{{modalError}}</b-alert>

      <div class="row">
        <div class="col-sm-9">
          <div class="form-group">
            <label class="control-label">Grupo/Departamento</label>
            
            <input
              id="departmentDescription"
              name="departmentDescription"
              type="text"
              class="form-control"
              :disabled="true"
              v-if="departmentLimitRule._id"
              :value="departmentLimitRule.departmentDescription"
            />

            <multiselect
              v-model="departmentLimitRule.department"
              :options="departments"
              placeholder="Selecione o departamento"
              label="description"
              track-by="_id"
              selectLabel="Enter para selecionar"
              selectedLabel="Selecionado"
              deselectLabel="Enter para remover"
              v-if="!departmentLimitRule._id"
            ></multiselect>
          </div>
        </div>

        <div class="col-sm-3">
          <div class="form-group">
            <label for="quantity">Qtde. Máxima</label>

            <input
              id="quantity"
              name="quantity"
              type="tel"
              class="form-control"
              v-model="departmentLimitRule.quantity"
            />
          </div>
        </div>
      </div>
      
      <template v-slot:modal-footer>
        <b-button variant="link" @click="$bvModal.hide('modal-edit')">Cancelar</b-button>
        <b-button variant="primary" @click="saveDepartmentLimitRule()">
          Gravar
        </b-button>
      </template>
    </b-modal>

    <b-modal id="modal-remove" title="Excluir Regra" title-class="font-18" centered>
      <p>Confirma a exclusão da regra?</p>

      <div slot="modal-footer">
        <b-btn variant="link" @click="$bvModal.hide('modal-remove')">Cancelar</b-btn>
        <b-btn variant="primary" @click="removeDepartmentLimitRule">Excluir</b-btn>
      </div>
    </b-modal>
  </Layout>
</template>

<style lang="scss">
  .search-input form {
    display: flex;
    flex-direction: row;

    .btn {
      margin-left: 10px;
    }
  }

  .form-control:disabled {
    background-color: #e6e6e6;
  }
</style>